.button-black {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: black;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: all 200ms ease-in-out;
}

.button-black-small {
  display: block;
  width: 20%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: black;
  color: white;
  cursor: pointer;
  font-size: 12px;
  transition: all 200ms ease-in-out;
}

.button-black:hover,
.button-black-small:hover {
  background-color: #ffd466;
}

.button-black:active,
.button-black-small:active {
  background-color: #ffab00;
}

.button-black:disabled,
.button-black-small:disabled {
  background-color: #d3d3d3;
}
