.gender-button {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the items horizontally */
  justify-content: center; /* Centers the items vertically */
  background: none;
  border: none;
  cursor: pointer;
  max-height: 300px;
  transition: transform 0.3s ease;
  padding: 10px; /* Add padding as needed */
}

.gender-button.selected {
  transform: scale(1.1); /* Slightly scale up the selected image */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

.gender-button:focus {
  outline: black;
}

.gender-button:active {
  transform: scale(0.9); /* Slightly scale down the image when clicked */
}

.gender-buttons {
  display: flex; /* This will lay out the children (buttons) in a row */
  justify-content: center; /* This will center the buttons in the container */
  align-items: center; /* This will align the buttons vertically in the center */
  gap: 20px; /* This adds some space between the buttons */
  max-width: 100%; /* Adjust this as necessary to fit the container's width */
  margin: 0 auto; /* This will center the buttons container in the parent element */
  padding-top: 20px;
}

.gender-button span {
  margin-top: auto; /* Pushes the label to the bottom */
  padding: 5px 0; /* Adds some padding at the top and bottom of the label */
}

.gender-image {
  height: auto; /* Adjust the height as needed, or keep it auto */
  width: 100%; /* Make the image responsive to the container width */
  max-width: 200px; /* Set a maximum width for the image */
  max-height: 400px; /* Set a maximum height for the image */
  object-fit: contain; /* This will ensure the image maintains its aspect ratio */
  border-radius: 5px;
  /* padding-top: 20px; */
}
