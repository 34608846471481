body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Thiccboi";
  src: url("./assets/fonts/THICCCBOI-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Thiccboi";
  src: url("./assets/fonts/THICCCBOI-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Thiccboi";
  src: url("./assets/fonts/THICCCBOI-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Thiccboi";
  src: url("./assets/fonts/THICCCBOI-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Thiccboi";
  src: url("./assets/fonts/THICCCBOI-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Thiccboi";
  src: url("./assets/fonts/THICCCBOI-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Thiccboi";
  src: url("./assets/fonts/THICCCBOI-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Thiccboi";
  src: url("./assets/fonts/THICCCBOI-ThicccAF.ttf") format("truetype");
  font-weight: 1000; /* This is not a standard weight, adjust as needed */
  font-style: normal;
}

@font-face {
  font-family: "Thiccboi";
  src: url("./assets/fonts/THICCCBOI-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
