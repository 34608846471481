.image-grid {
}

.good-image {
    height: auto; /* Adjust the height as needed, or keep it auto */
    width: 100%; /* Make the image responsive to the container width */
    max-width: 200px; /* Set a maximum width for the image */
    max-height: 400px; /* Set a maximum height for the image */
    object-fit: contain; /* This will ensure the image maintains its aspect ratio */
    border-radius: 5px;
    margin: 5px;
  }
  
  .how-it-works-image {
    height: auto; /* Adjust the height as needed, or keep it auto */
    width: 100%; /* Make the image responsive to the container width */
    object-fit: contain; /* This will ensure the image maintains its aspect ratio */
    border-radius: 5px;
    margin: 5px;
    padding-top: 20px;
  }