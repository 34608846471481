body {
    text-align: center;
}


.uploaded-photo {
    width: 100px; /* or any other size */
    height: auto;
    margin: 5px;
    border: 1px solid #ddd; /* optional, for styling */
    border-radius: 4px; /* optional, for styling */
    transition: transform 0.3s ease;
}

.uploaded-photo:hover {
    transform: scale(1.1); /* Scale up the photo slightly on hover */
}

.summary-step {
    /* Styles for the summary step */

}

.summary-content {
    /* Styles for the content */

}

.summary-content p {
    /* Styles for each paragraph */
}

.summary-content ul {
    /* Styles for the list */
}

