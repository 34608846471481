.photo-upload-container {
  text-align: center;
  width: 100%;
  font-family: "Thiccboi", sans-serif;
}

.dropzone {
  border: 2px dashed black;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin: auto;
  cursor: pointer;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust the height as needed */
}

.dropzone p,
.dropzone small {
  margin: 5px 0;
}

.dropzone i {
  font-size: 24px; /* FontAwesome icon size */
  margin: 10px 0;
}

.file-upload-details {
  color: #666;
  font-size: 12px;
  margin-top: 10px;
}

.file-upload-status {
  color: #28a745; /* A success color, you can choose what you like */
  font-size: 16px;
  margin-top: 10px;
}

/* Style adjustments for mobile screens */
@media (max-width: 768px) {
  .dropzone {
    width: 95%;
    padding: 10px;
  }
}
