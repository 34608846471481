* {
  box-sizing: border-box;
  /* margin: 10px; */
  padding: 0;
}

body {
  overflow-x: hidden;
}

.get-your-photos {
  position: absolute; /* New: Set position to absolute */
  top: 20%; /* Adjust this value to position the div vertically */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust horizontal centering */
  width: 700px; /* Fixed width */
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

@keyframes enterAnimation {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes exitAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.fade-enter {
  animation: enterAnimation 0.2s forwards;
}

.fade-exit {
  animation: exitAnimation 0.2s forwards;
}

.form-container {
  position: relative;
}

.linear-loader {
  width: 100%;
  background-color: #f1f1f1;
  height: 4px;
  position: relative;
}

.loader-bar {
  background-color: black;
  height: 100%;
  width: 0;
  position: absolute;
  margin-top: 20px;
  animation: loading 2s ease-in-out infinite;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 30px 10px;
}

.logo {
  width: 200px; /* Adjust as needed */
}

.sign-out-button {
  display: block;
  width: 100px;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: black;
  color: white;
  cursor: pointer;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

/* Add a media query for small screens */
@media (max-width: 700px) {
  body {
    margin: 0; /* Remove default body margin */
  }

  .get-your-photos {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    /* padding: 0; */
    transform: none; /* Remove transform property */
  }

  #gender-step {
    padding: 20px;
  }

  .stepper-container {
    flex-wrap: wrap;
    gap: 20px;
  }

  .nav-bar {
    flex-direction: row; /* Change the direction to row */
    /* flex-wrap: wrap; */
    /* justify-content: space-between; Add space between the logo and the sign out button */
  }

  .logo {
    order: 1;
  }

  .sign-out-button {
    order: 2;
  }

  .button-black {
    width: 100% !important; /* Make the buttons take up the full width on small screens */
  }
}

@keyframes loading {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
